import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './Styles';

type Props = {
  term: string;
};

export function ListeningTermEmptyState({ term }: Props) {
  const navigate = useNavigate();

  return (
    <S.MainContainer>
      <S.ContainerWrap>
        <S.TopNavbarContainer>
          <S.TopNavbarInnerContainer>
            <S.TopNavbarButton onClick={() => navigate(-1)}>
              <svg viewBox="0 0 20 20">
                <path
                  d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
                  fill="#292929"
                />
              </svg>
            </S.TopNavbarButton>
          </S.TopNavbarInnerContainer>
        </S.TopNavbarContainer>

        <S.EmptyState>
          <S.EmptyStateIcon>
            <svg viewBox="0 0 32 32">
              <path
                d="M16.051 28.349c-6.919-0.023-12.378-5.53-12.373-12.357 0.005-6.884 5.522-12.372 12.383-12.34 6.838 0.032 12.269 5.534 12.261 12.35-0.008 6.899-5.513 12.286-12.271 12.348zM15.982 19.242c0 0 0 0.001 0 0.001 0.296 0 0.592 0.001 0.889-0 0.729-0.003 0.66 0.088 0.727-0.688 0.119-1.358 0.197-2.72 0.29-4.081 0.058-0.841 0.11-1.683 0.172-2.524 0.041-0.563 0.088-1.127 0.144-1.689 0.061-0.616 0.133-1.226-0.151-1.817-0.505-1.053-1.489-1.487-2.54-1.274-1.087 0.22-1.794 1.18-1.768 2.301 0.011 0.502 0.069 1.002 0.104 1.503 0.043 0.618 0.081 1.236 0.128 1.853 0.098 1.297 0.201 2.593 0.301 3.89 0.057 0.742 0.111 1.485 0.167 2.228 0.015 0.201 0.106 0.306 0.326 0.3 0.404-0.011 0.808-0.003 1.212-0.003zM14.157 23.027c-0.066 0.985 0.788 1.851 1.799 1.863 1.109 0.014 1.874-0.861 1.895-1.836 0.023-1.028-0.84-1.866-1.815-1.891-0.996-0.026-1.957 0.847-1.879 1.864z"
                fill="#BFBFBF"
              />
            </svg>
          </S.EmptyStateIcon>

          <S.EmptyStateTitle>
            {`${t('listeningTerm.titleEmptyState')}"${term}"`}
          </S.EmptyStateTitle>

          <S.EmptyStateDescription>
            {t('listeningTerm.descriptionEmptyState')}
          </S.EmptyStateDescription>
        </S.EmptyState>
      </S.ContainerWrap>
    </S.MainContainer>
  );
}
