import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 4rem;
  align-items: center;
  flex-direction: column;
  transition: all 0.08s ease;
`;

export const ContainerWrap = styled.div`
  width: 120rem;
  max-width: 100%;
`;

export const ListeningHeadSection = styled.div`
  padding: 0 10rem;

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const ListeningTitle = styled.div`
  color: #292929;
  margin-top: 4rem;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4rem;
  text-align: center;
  margin-bottom: 4rem;
`;

export const ListeningOptions = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem;
  margin-bottom: 6rem;

  @media (max-width: 750px) {
    gap: 1.6rem;
    margin-bottom: 2rem;
    flex-direction: column;

    & > div {
      justify-content: center;
    }
  }
`;

export const FilterTags = styled.div`
  height: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin-right: 2rem;
  align-items: center;
  display: inline-flex;
  width: calc(100% - 2.5rem);

  @media (max-width: 750px) {
    width: 100%;
    flex-wrap: wrap;
    margin-right: 0;
    overflow: initial;
  }
`;

export const Tag = styled.div<{ active?: boolean }>`
  height: 4rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  align-items: center;
  white-space: nowrap;
  border-radius: 2rem;
  display: inline-flex;
  margin-right: 0.6rem;
  justify-content: center;
  border: ${({ active }) =>
    active ? 'solid 1px #292929;' : 'solid 1px #E0E5EA'};
  color: ${({ active }) => (active ? '#ffffff' : '#292929')};
  background: ${({ active }) => (active ? '#292929' : '#ffffff')};
  cursor: pointer;

  &:hover {
    ${({ active }) =>
      !active &&
      `
      background: #EFF1F6;
      border-color: #EFF1F6;
    `}
  }

  @media (max-width: 850px) {
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 750px) {
    margin-top: 0.8rem;
  }
`;

export const FilterOptions = styled.div`
  gap: 1.2rem;
  display: flex;
`;
