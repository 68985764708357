import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  margin: 1.6rem 0;
`;

export const PostsContainer = styled.div`
  gap: 1.6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SocialPost = styled.div`
  flex: 0 0 calc(25% - 1.2rem);
  max-width: calc(25% - 1.2rem);

  @media (max-width: 1250px) {
    flex: 0 0 calc(33% - 0.8rem);
    max-width: calc(33% - 0.8rem);
  }

  @media (max-width: 1000px) {
    flex: 0 0 calc(50% - 0.8rem);
    max-width: calc(50% - 0.8rem);
  }

  @media (max-width: 650px) {
    flex: 0 0 calc(100% - 0.8rem);
    max-width: calc(100% - 0.8rem);
  }
`;

export const PostCard = styled.div`
  position: relative;
  border-radius: 2rem;
  background-color: #fff;
  transition: transform 0.2s;
  border: solid 1px #e0e5ea;

  &:hover {
    transform: scale(1.03);
  }
`;

export const PostHead = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 1.5rem 2rem 2rem;
  justify-content: space-between;
`;

export const CreatorContainer = styled.div`
  height: 100%;
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  overflow-x: hidden;
  align-items: center;
  display: inline-flex;
`;

export const CreatorPicture = styled.div`
  width: 4rem;

  img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    margin-right: 2rem;
    border-radius: 9999px;
  }
`;

export const NetworkIcon = styled.div`
  padding: 0.2rem;
  background: white;
  margin-top: 2.4rem;
  align-items: center;
  margin-left: -1.6rem;
  display: inline-flex;
  border-radius: 0.6rem;
  justify-content: center;
`;

export const CreatorDetails = styled.div`
  gap: 0.2rem;
  display: flex;
  overflow-x: overlay;
  margin-left: 1.2rem;
  flex-direction: column;
`;

export const CreatorUsername = styled.div`
  color: #292929;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.4rem;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`;

export const CreatorMetric = styled.div`
  display: flex;
  color: #606976;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const PostMedia = styled.div`
  height: 30rem;
  display: flex;
  position: relative;
  align-items: center;
  background: #e7eaf0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const PostActionOverlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  place-items: center;
  transition: opacity 0.3s;
  background-color: rgba(22, 43, 57, 0.3);

  &:hover {
    opacity: 1;
  }
`;

export const PostOverlayButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
`;

export const PostType = styled.div`
  top: 1.6rem;
  right: 1.8rem;
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
`;

export const PostDescription = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 6.8rem;
  align-items: center;
  padding: 0.12rem 2rem;
`;

export const PostText = styled.p`
  width: 100%;
  color: #8492a6;
  overflow: hidden;
  font-size: 1.2rem;
  display: -webkit-box;
  text-decoration: none;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  cursor: text;
`;

export const PostMetrics = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem 2rem;
`;

export const PostMetric = styled.div`
  width: 100%;
  flex: 1 0 25%;
  display: flex;
  min-width: 33%;
  max-width: 25%;
  line-height: 1.5;
  -webkit-box-flex: 1;
  flex-direction: column;
`;

export const PostMetricLabel = styled.span`
  color: #8492a6;
  font-size: 1rem;
  line-height: 1.5;
`;

export const PostMetricValue = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  white-space: nowrap;
`;
