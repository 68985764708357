import { t } from 'i18next';

import * as S from './Styles';

type Props = {
  title: string;
  placeholder: string;
  validFilters: boolean;
  value: string | undefined;
  setValue: (value: string) => void;
};

export function ListeningFilterTextInput({
  title,
  value,
  setValue,
  placeholder,
  validFilters,
}: Props) {
  return (
    <S.InputContainer>
      <S.InputTitle>{t(`discover.${title}`)}</S.InputTitle>

      <input
        type="text"
        value={value}
        name={`filters.${title}`}
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
      />

      {!validFilters && (
        <S.ErrorText>
          * {t(`discover.${title}`) + t('listening.cannotBeEmpty')}
        </S.ErrorText>
      )}
    </S.InputContainer>
  );
}
