/* eslint-disable no-console */
import {
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
  createContext,
} from 'react';
import {
  SocialNetworks,
  TCountriesAvailableValues,
} from 'Services/Utils/types';
import {
  useGetLocations,
  useGetTopicsInterest,
} from 'Services/Utils/CustomHooks';
import {
  DiscoverFormData,
  discoverFormSchema,
} from 'features/Discover/schemas/discover-form';
import {
  SortOrder,
  SortByFields,
  GetPublicUsersByFilterResponse,
} from 'Services/SkorrApi/new/User/types';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { usePrevious } from 'Services/Hooks/use-previous';
import { useGetPublicUsersByFilter } from 'features/Discover/api';
import { TimeFrame, TopicsOfInterest } from 'features/Discover/types';

import { zodResolver } from '@hookform/resolvers/zod';

export type DiscoverContext = {
  page: number;
  showSaveList: boolean;
  timeFrame: TimeFrame[];
  resultsPerPage: number;
  sortByField: SortByFields;
  toggleFilterButton: boolean;
  toggleOrderByButton: boolean;
  showTimeFramePicker: boolean;
  gender: string[] | undefined;
  category: string | undefined;
  handler: string[] | undefined;
  selectAllUsersToggle: boolean;
  keywords: string[] | undefined;
  countryCode: string[] | undefined;
  topicsInterest: string[] | undefined;
  network: SocialNetworks[] | undefined;
  selectedUserIds: string[] | undefined;
  isLoadingPublicUsersByFilter: boolean;
  countriesAvailableValues: TCountriesAvailableValues;
  topicsOfInterestAvailableValues: TopicsOfInterest[];
  publicUsersByFilter: GetPublicUsersByFilterResponse | undefined;
  handleClearFilters: () => void;
  handleOpenSaveList: () => void;
  handleCloseSaveList: () => void;
  selectedUsersCount: () => number;
  handleSelectAllUsers: () => void;
  handleToggleFilterButton: () => void;
  saveListButtonDisabled: () => boolean;
  handleToggleOrderByButton: () => void;
  setTimeFrame: (value: TimeFrame[]) => void;
  isUserSelected: (userId: string) => boolean;
  handleClickOutToggleFilterButton: () => void;
  handleClickOutToggleOrderByButton: () => void;
  handleChangePage: (pageNumber: number) => void;
  handleUserCardClicked: (userId: string) => void;
  setShowTimeFramePicker: (value: boolean) => void;
  handleGoToUserMediaKit: (userId: string) => void;
  handleApplyFilters: (resetPage?: boolean) => Promise<void>;
  handleChangeOrderBy: (orderKey: keyof typeof SortByFields) => void;
};

type DiscoverProviderProps = {
  children: ReactNode;
};

export const DiscoverProviderContext = createContext({} as DiscoverContext);

function DiscoverProvider({ children }: DiscoverProviderProps) {
  const navigate = useNavigate();

  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: new Date(),
        key: 'selection',
      },
    ];
  });

  const [showSaveList, setShowSaveList] = useState(false);
  const [toggleFilterButton, setToggleFilterButton] = useState(false);
  const [toggleOrderByButton, setToggleOrderByButton] = useState(false);
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);
  const [selectAllUsersToggle, setSelectAllUsersToggle] = useState(false);

  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [topicsOfInterestAvailableValues, setTopicsOfInterestAvailableValues] =
    useState<TopicsOfInterest[]>([]);

  const handleOpenSaveList = useCallback(() => {
    setShowSaveList(true);
  }, []);

  const handleCloseSaveList = useCallback(() => {
    setShowSaveList(false);
  }, []);

  const handleToggleOrderByButton = useCallback(() => {
    setTimeout(() => setToggleOrderByButton(!toggleOrderByButton), 0);
  }, [toggleOrderByButton]);

  const handleClickOutToggleOrderByButton = useCallback(() => {
    if (toggleOrderByButton) {
      setToggleOrderByButton(false);
    }
  }, [toggleOrderByButton]);

  const handleToggleFilterButton = useCallback(() => {
    setTimeout(() => setToggleFilterButton(!toggleFilterButton), 0);
  }, [toggleFilterButton]);

  const handleClickOutToggleFilterButton = useCallback(() => {
    if (toggleFilterButton) {
      setToggleFilterButton(false);
    }
  }, [toggleFilterButton]);

  const handleGoToUserMediaKit = useCallback((userId: string) => {
    navigate(`/discover/user-media-kit/${userId}`);
  }, []);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const { data: topicsOfInterest, isFetching: isFetchingGetTopicsInterest } =
    useGetTopicsInterest(true);

  useEffect(() => {
    if (topicsOfInterest && !isFetchingGetTopicsInterest) {
      const topicsOfInterestAvailableValues: TopicsOfInterest[] = [];

      topicsOfInterest.forEach((entry, key) => {
        topicsOfInterestAvailableValues.push({
          value: key,
          label: t(entry),
        });
      });

      setTopicsOfInterestAvailableValues(topicsOfInterestAvailableValues);
    }
  }, [topicsOfInterest, isFetchingGetTopicsInterest]);

  const discoverForm = useForm<DiscoverFormData>({
    resolver: zodResolver(discoverFormSchema),
    defaultValues: {
      filters: {
        handler: [],
        gender: [],
        topicsInterest: [],
        category: '',
        publicUserIds: [],
        network: [],
        countryCode: [],
        keywords: [],
        audienceValueHigh: 0,
        audienceValueLow: 0,
        engValueHigh: 0,
        engValueLow: 0,
        postsValueHigh: 0,
        postsValueLow: 0,
        likesValueHigh: 0,
        likesValueLow: 0,
        sharesValueHigh: 0,
        sharesValueLow: 0,
        commentsValueHigh: 0,
        commentsValueLow: 0,
        startDate: new Date(
          new Date().setMonth(new Date().getMonth() - 1),
        ).getTime(),
        endDate: new Date().getTime(),
      },
      page: 0,
      limit: 50,
      sort: SortByFields.audience,
      sortOrder: SortOrder.DESC,
      selectedUserIds: [],
    },
    mode: 'all',
  });

  const {
    watch,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    reset: resetDiscoverForm,
  } = discoverForm;

  const page = watch('page');
  const sortByField = watch('sort');
  const sortOrder = watch('sortOrder');
  const resultsPerPage = watch('limit');
  const gender = watch('filters.gender');
  const handler = watch('filters.handler');
  const network = watch('filters.network');
  const keywords = watch('filters.keywords');
  const category = watch('filters.category');
  const countryCode = watch('filters.countryCode');
  const selectedUserIds = watch('selectedUserIds');
  const topicsInterest = watch('filters.topicsInterest');
  const getSelectedUserIds = getValues('selectedUserIds');

  const prevHandler = usePrevious(handler);
  const prevSortOrder = usePrevious(sortOrder);
  const prevSortByField = usePrevious(sortByField);

  const handleChangeOrderBy = useCallback(
    (orderKey: keyof typeof SortByFields) => {
      if (orderKey === sortByField) {
        if (sortOrder === SortOrder.DESC) {
          setValue('sortOrder', SortOrder.ASC);
        } else {
          setValue('sortOrder', SortOrder.DESC);
        }
      } else {
        setValue('sortOrder', SortOrder.DESC);
      }

      setValue('sort', SortByFields[orderKey]);
      setToggleOrderByButton(false);
    },
    [sortOrder, sortByField],
  );

  const checkArray = useCallback((arr: string[] | undefined) => {
    const trimmedArray = arr?.map(str => str.trim());

    if (
      !trimmedArray ||
      trimmedArray.length === 0 ||
      (trimmedArray.length === 1 && trimmedArray[0] === '')
    ) {
      return undefined;
    }

    return trimmedArray;
  }, []);

  const {
    data: publicUsersByFilter,
    mutateAsync: getPublicUsersByFilter,
    isLoading: isLoadingPublicUsersByFilter,
  } = useGetPublicUsersByFilter();

  const handleApplyFilters = useCallback(
    async (resetPage?: boolean) => {
      if (resetPage) {
        setValue('page', 0);
      }

      handleSubmit(async (data: DiscoverFormData) => {
        try {
          await getPublicUsersByFilter({
            filters: {
              handler: checkArray(data.filters.handler),
              gender:
                data.filters.category === 'INFLUENCER'
                  ? checkArray(data.filters.gender)
                  : undefined,
              topicsInterest:
                data.filters.category !== '' &&
                data.filters.category !== undefined
                  ? checkArray(data.filters.topicsInterest)
                  : undefined,
              category:
                data.filters.category !== ''
                  ? data.filters.category
                  : undefined,
              publicUserIds: checkArray(data.filters.publicUserIds),
              network:
                data.filters.network?.length! > 0
                  ? data.filters.network
                  : undefined,
              countryCode: checkArray(data.filters.countryCode),
              keywords: checkArray(data.filters.keywords),
              audienceValueLow:
                data.filters.audienceValueLow !== 0
                  ? data.filters.audienceValueLow
                  : undefined,
              audienceValueHigh:
                data.filters.audienceValueHigh !== 0
                  ? data.filters.audienceValueHigh
                  : undefined,
              engValueLow:
                data.filters.engValueLow !== 0
                  ? data.filters.engValueLow
                  : undefined,
              engValueHigh:
                data.filters.engValueHigh !== 0
                  ? data.filters.engValueHigh
                  : undefined,
              postsValueLow:
                data.filters.postsValueLow !== 0
                  ? data.filters.postsValueLow
                  : undefined,
              postsValueHigh:
                data.filters.postsValueHigh !== 0
                  ? data.filters.postsValueHigh
                  : undefined,
              likesValueLow:
                data.filters.likesValueLow !== 0
                  ? data.filters.likesValueLow
                  : undefined,
              likesValueHigh:
                data.filters.likesValueHigh !== 0
                  ? data.filters.likesValueHigh
                  : undefined,
              sharesValueLow:
                data.filters.sharesValueLow !== 0
                  ? data.filters.sharesValueLow
                  : undefined,
              sharesValueHigh:
                data.filters.sharesValueHigh !== 0
                  ? data.filters.sharesValueHigh
                  : undefined,
              commentsValueLow:
                data.filters.commentsValueLow !== 0
                  ? data.filters.commentsValueLow
                  : undefined,
              commentsValueHigh:
                data.filters.commentsValueHigh !== 0
                  ? data.filters.commentsValueHigh
                  : undefined,
              startDate: checkArray(data.filters.keywords)
                ? data.filters.startDate
                : undefined,
              endDate: checkArray(data.filters.keywords)
                ? data.filters.endDate
                : undefined,
            },
            page: data.page,
            limit: data.limit,
            sort: data.sort,
            sortOrder: data.sortOrder,
          });

          handleClickOutToggleFilterButton();
        } catch (err) {
          console.log('ERROR---> ', err);
        }
      })();
    },
    [
      setValue,
      handleSubmit,
      getPublicUsersByFilter,
      handleClickOutToggleFilterButton,
    ],
  );

  const handleClearFilters = useCallback(() => {
    resetDiscoverForm();

    setTimeout(() => {
      handleApplyFilters();
    }, 100);
  }, [resetDiscoverForm, handleApplyFilters]);

  const handleUserCardClicked = useCallback(
    (userId: string) => {
      if (getSelectedUserIds) {
        if (getSelectedUserIds.some(id => id === userId)) {
          setValue(
            'selectedUserIds',
            getSelectedUserIds.filter(id => id !== userId),
          );
        } else {
          setValue('selectedUserIds', [...getSelectedUserIds, userId]);
        }
      }
    },
    [getSelectedUserIds, setValue],
  );

  const isUserSelected = useCallback(
    (userId: string) => {
      if ((selectedUserIds ?? []).includes(userId)) {
        return true;
      }
      return false;
    },
    [selectedUserIds],
  );

  const handleSelectAllUsers = useCallback(() => {
    const allUserIds = publicUsersByFilter?.publicUsers?.map(user => user._id!);

    setSelectAllUsersToggle(prevState => {
      setValue('selectedUserIds', !prevState ? allUserIds : []);
      return !prevState;
    });
  }, [publicUsersByFilter, setValue]);

  const selectedUsersCount = useCallback(() => {
    return (selectedUserIds ?? []).length;
  }, [selectedUserIds]);

  const saveListButtonDisabled = useCallback(() => {
    if (!selectAllUsersToggle && (selectedUserIds ?? []).length === 0) {
      return true;
    }
    return false;
  }, [selectAllUsersToggle, selectedUserIds]);

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      setValue('page', pageNumber);

      setTimeout(() => {
        handleApplyFilters();
      }, 100);
    },
    [handleApplyFilters],
  );

  useEffect(() => {
    trigger('filters.keywords');
  }, [keywords]);

  useEffect(() => {
    trigger('filters.countryCode');
  }, [countryCode]);

  useEffect(() => {
    const debouncedFunction = debounce(() => handleApplyFilters(true), 500);

    if (
      !toggleFilterButton &&
      handler?.[0]?.length! > 2 &&
      prevHandler?.[0] !== handler?.[0]
    ) {
      debouncedFunction();
    }

    return () => {
      debouncedFunction.cancel();
    };
  }, [toggleFilterButton, handler]);

  useEffect(() => {
    if (
      !toggleOrderByButton &&
      (prevSortOrder !== sortOrder || prevSortByField !== sortByField)
    ) {
      handleApplyFilters(true);
    }
  }, [
    sortOrder,
    sortByField,
    prevSortOrder,
    prevSortByField,
    toggleOrderByButton,
    handleApplyFilters,
  ]);

  return (
    <DiscoverProviderContext.Provider
      value={{
        page,
        gender,
        handler,
        network,
        keywords,
        category,
        timeFrame,
        countryCode,
        sortByField,
        setTimeFrame,
        showSaveList,
        topicsInterest,
        resultsPerPage,
        selectedUserIds,
        toggleFilterButton,
        toggleOrderByButton,
        showTimeFramePicker,
        publicUsersByFilter,
        selectAllUsersToggle,
        countriesAvailableValues,
        isLoadingPublicUsersByFilter,
        topicsOfInterestAvailableValues,
        isUserSelected,
        handleChangePage,
        handleApplyFilters,
        handleClearFilters,
        selectedUsersCount,
        handleOpenSaveList,
        handleChangeOrderBy,
        handleCloseSaveList,
        handleSelectAllUsers,
        handleUserCardClicked,
        setShowTimeFramePicker,
        handleGoToUserMediaKit,
        saveListButtonDisabled,
        handleToggleFilterButton,
        handleToggleOrderByButton,
        handleClickOutToggleFilterButton,
        handleClickOutToggleOrderByButton,
      }}
    >
      <FormProvider {...discoverForm}>{children}</FormProvider>
    </DiscoverProviderContext.Provider>
  );
}

function useDiscoverContext() {
  const context = useContext(DiscoverProviderContext);

  return context;
}

export { DiscoverProvider, useDiscoverContext };
