import { t } from 'i18next';
import moment from 'moment';
import { useCallback } from 'react';
import EyeIcon from 'assets/iconComponents/EyeIcon';
import { PublicPostByFilter } from 'Services/SkorrApi/new/Post';
import { PublicPostTypes } from 'Services/SkorrApi/new/Post/types';
import PostTypeTextIcon from 'assets/iconComponents/PostTypeTextIcon';
import PostTypeVideoIcon from 'assets/iconComponents/PostTypeVideoIcon';
import { DEFAULT_USER_AVATAR, SocialNetworks } from 'Services/Utils/types';
import PostTypeGalleryIcon from 'assets/iconComponents/PostTypeGalleryIcon';
import PostTypeSingleImageIcon from 'assets/iconComponents/PostTypeSingleImageIcon';
import {
  getSocialNetworkIcon,
  convertNumberToHumanStringfiedFormat,
} from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  posts: PublicPostByFilter[];
};

export function ListeningTermPosts({ posts }: Props) {
  const getFormattedPostKpiValue = useCallback((value?: number) => {
    if (value === -1) {
      return '-';
    }

    return convertNumberToHumanStringfiedFormat(value ?? 0);
  }, []);

  const getPostTypeIcon = (postType: PublicPostTypes) => {
    switch (postType) {
      case PublicPostTypes.STORY:
      case PublicPostTypes.IMAGE:
        return <PostTypeSingleImageIcon fill={'white'} />;
      case PublicPostTypes.CAROUSEL:
        return <PostTypeGalleryIcon fill={'white'} />;
      case PublicPostTypes.VIDEO:
        return <PostTypeVideoIcon fill={'white'} />;
      default:
        return <PostTypeTextIcon fill={'white'} />;
    }
  };

  return (
    <>
      <S.MainContainer>
        <S.PostsContainer>
          {posts.map(post => (
            <S.SocialPost key={post.id}>
              <S.PostCard>
                <S.PostHead>
                  <S.CreatorContainer>
                    <S.CreatorPicture>
                      <img
                        src={post.user?.displayPicture || DEFAULT_USER_AVATAR}
                      />
                    </S.CreatorPicture>

                    <S.NetworkIcon>
                      {getSocialNetworkIcon({
                        socialNetwork: post.socialNetwork,
                        width: '2rem',
                        height: '2rem',
                        gradientColored: true,
                      })}
                    </S.NetworkIcon>

                    <S.CreatorDetails>
                      <S.CreatorUsername>{post.username}</S.CreatorUsername>

                      <S.CreatorMetric>
                        {moment(new Date(post.date)).format('YYYY-MM-DD')}
                      </S.CreatorMetric>
                    </S.CreatorDetails>
                  </S.CreatorContainer>
                </S.PostHead>

                <S.PostMedia>
                  <S.PostActionOverlay>
                    <S.PostOverlayButton
                      onClick={() => window.open(post.url, '_blank')}
                    >
                      <EyeIcon fill={'white'} width={'6rem'} height={'6rem'} />
                    </S.PostOverlayButton>
                  </S.PostActionOverlay>

                  <S.PostType>{getPostTypeIcon(post.type)}</S.PostType>

                  <img src={post.image} />
                </S.PostMedia>

                <S.PostDescription>
                  <S.PostText>{post.content}</S.PostText>
                </S.PostDescription>

                <S.PostMetrics>
                  {post.socialNetwork === SocialNetworks.YOUTUBE &&
                    post.type !== PublicPostTypes.STORY && (
                      <S.PostMetric>
                        <S.PostMetricLabel>
                          {t('mediaKit.post.reactions.views')}
                        </S.PostMetricLabel>

                        <S.PostMetricValue>
                          {getFormattedPostKpiValue(post.kpis.views)}
                        </S.PostMetricValue>
                      </S.PostMetric>
                    )}

                  {post.type !== PublicPostTypes.STORY && (
                    <S.PostMetric>
                      <S.PostMetricLabel>
                        {t('mediaKit.post.reactions.likes')}
                      </S.PostMetricLabel>

                      <S.PostMetricValue>
                        {getFormattedPostKpiValue(post.kpis.likes)}
                      </S.PostMetricValue>
                    </S.PostMetric>
                  )}

                  {post.type !== PublicPostTypes.STORY && (
                    <S.PostMetric>
                      <S.PostMetricLabel>
                        {t('mediaKit.post.reactions.comments')}
                      </S.PostMetricLabel>

                      <S.PostMetricValue>
                        {getFormattedPostKpiValue(post.kpis.comments)}
                      </S.PostMetricValue>
                    </S.PostMetric>
                  )}

                  {post.type !== PublicPostTypes.STORY &&
                    post.socialNetwork !== SocialNetworks.YOUTUBE && (
                      <S.PostMetric>
                        <S.PostMetricLabel>
                          {t('mediaKit.post.reactions.shares')}
                        </S.PostMetricLabel>

                        <S.PostMetricValue>
                          {getFormattedPostKpiValue(post.kpis.shares)}
                        </S.PostMetricValue>
                      </S.PostMetric>
                    )}

                  {post.type === PublicPostTypes.STORY && (
                    <S.PostMetric>
                      <S.PostMetricLabel>
                        {t('generic.reactions')}
                      </S.PostMetricLabel>

                      <S.PostMetricValue>
                        {getFormattedPostKpiValue(post.kpis.reactions)}
                      </S.PostMetricValue>
                    </S.PostMetric>
                  )}
                </S.PostMetrics>
              </S.PostCard>
            </S.SocialPost>
          ))}
        </S.PostsContainer>
      </S.MainContainer>
    </>
  );
}
