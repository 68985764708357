import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  margin: 1.6rem 0;
`;

export const InnerContainer = styled.div`
  gap: 1.6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const CardOuterContainer = styled.div`
  flex: 0 0 calc(25% - 1.2rem);
  max-width: calc(25% - 1.2rem);

  @media (max-width: 1250px) {
    flex: 0 0 calc(33% - 0.8rem);
    max-width: calc(33% - 0.8rem);
  }

  @media (max-width: 1000px) {
    flex: 0 0 calc(50% - 0.8rem);
    max-width: calc(50% - 0.8rem);
  }

  @media (max-width: 650px) {
    flex: 0 0 calc(100% - 0.8rem);
    max-width: calc(100% - 0.8rem);
  }
`;

export const CardInnerContainer = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 2rem;
  background-color: #fff;
  transition: transform 0.2s;
  border: solid 1px #e0e5ea;

  &:hover {
    transform: scale(1.03);
  }
`;

export const CardPictureContainer = styled.div`
  height: 23vw;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  background: rgb(231, 234, 240);

  @media (min-width: 1665px) {
    height: 18.5vw;
  }

  @media (min-width: 1285px) and (max-width: 1664px) {
    height: 23vw;
  }

  @media (min-width: 992px) and (max-width: 1284px) {
    height: 31vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 46vw;
  }

  @media (min-width: 665px) and (max-width: 767px) {
    height: 45vw;
  }

  @media (max-width: 665px) {
    height: 91vw;
  }
`;

export const CardPictureOverlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  place-items: center;
  -webkit-box-align: center;
  transition: opacity 0.3s ease 0s;
  background-color: rgba(22, 43, 57, 0.3);

  &:hover {
    opacity: 1;
  }
`;

export const CardPictureOverlayActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const CardPictureOverlayIcon = styled.i`
  cursor: pointer;
`;

export const CardPicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const CardUserInfoContainer = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  position: absolute;
  align-items: center;
  justify-content: space-between;
`;

export const CardUsernameText = styled.h6`
  color: #fff;
  font-weight: 600;
  line-height: 1.6;
  font-size: 1.4rem;
`;

export const CardHandlerText = styled.span`
  color: #fff;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.2rem;
`;

export const CardDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 6.9rem;
  align-items: center;
  padding: 1.4rem 2rem;
  -webkit-box-align: center;

  @media (max-width: 272px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const CardDescriptionTextContainer = styled.a`
  flex-grow: 1;
  color: #292929;
  display: block;
  font-size: 1.28rem;
  overflow-x: overlay;
  -webkit-box-flex: 1;
  margin-right: 2.5rem;
  text-decoration: none;

  @media (max-width: 272px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const CardDescriptionText = styled.p`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const CardCountryContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10rem;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  @media (max-width: 272px) {
    width: 100%;
    margin-bottom: 0.2rem;
  }
`;

export const CardGlobeIconContainer = styled.div`
  fill: #8492a6;
`;

export const CardKpisContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 2rem 2rem;
`;

export const CardKpiContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  flex: 1 1 50%;
  display: flex;
  line-height: 1.5;
  min-width: 8.5rem;
  margin-bottom: 1rem;

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
`;

export const CardKpiInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KpiLabel = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(132, 146, 166);
`;

export const KpiValue = styled.span`
  line-height: 2;
  font-weight: 700;
  font-size: 1.4rem;
  white-space: nowrap;
  color: rgb(22, 43, 57);
  letter-spacing: 0.015rem;
`;
