import { SocialNetworks } from 'Services/Utils/types';

export type ParamsFilters = {
  endDate: number;
  startDate: number;
  keywords: string[];
  countryCode: string[];
  network: SocialNetworks[] | undefined;
};

export type SearchesLocalStorage = {
  term: string;
  numUsers: number;
  numPosts: number;
  engRate: number;
  filters: ParamsFilters;
};

export enum ListeningSortByField {
  'creators' = 'creators',
  'posts' = 'posts',
  'engRate' = 'engRate',
}
