import i18n from 'i18n/i18n';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';
import InstagramIcon from 'assets/iconComponents/InstagramIcon';

export const socialNetworkAvailableValues = [
  {
    value: 'INSTAGRAM',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InstagramIcon
          svgWidth="2rem"
          svgHeight="2rem"
          gradientColored={true}
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.INSTAGRAM')}
      </div>
    ),
  },
  {
    value: 'YOUTUBE',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <YoutubeIcon
          svgWidth="2rem"
          svgHeight="2rem"
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.YOUTUBE')}
      </div>
    ),
  },
  {
    value: 'TIKTOK',
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TiktokIcon
          svgWidth="2rem"
          svgHeight="2rem"
          containerMarginRight="1rem"
        />
        {i18n.t('socialNetworks.TIKTOK')}
      </div>
    ),
  },
];

export const LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY =
  'listeningPreviousSearches';
