/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';

import * as S from './Styles';

type PaginationOptions = {
  min?: number;
  total?: number;
  length?: number;
};

type Props = {
  page: number;
  totalRecords: number;
  totalRecordsPerPage: number;
  changePage: (page: number) => void;
};

const Pagination = ({
  page,
  totalRecords,
  totalRecordsPerPage,
  changePage,
}: Props) => {
  const [defaultRecordsPerPage, _] = useState(20);

  const renderActiveItem = (pageNumber: number) => {
    return (
      <S.PageNavListItem active>
        <a onClick={e => changePage(pageNumber - 1)}>{pageNumber}</a>
      </S.PageNavListItem>
    );
  };

  const renderInactiveItem = (pageNumber: number) => {
    return (
      <S.PageNavListItem>
        <a onClick={e => changePage(pageNumber - 1)}>{pageNumber}</a>
      </S.PageNavListItem>
    );
  };

  const pagination = (
    current: number,
    { min = 1, total = 20, length = 5 }: PaginationOptions = {},
  ) => {
    if (length > total) length = total;

    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length);

    return Array.from({ length }, (el, i) => start + i);
  };

  const getTotalRecords = () => {
    if (totalRecordsPerPage) {
      return totalRecordsPerPage;
    }

    return defaultRecordsPerPage;
  };

  const preparePageMovement = (pageNumber: number, textLabel: string) => {
    if (
      pageNumber >= 0 &&
      totalRecords > getTotalRecords() &&
      pageNumber < Math.ceil(totalRecords / getTotalRecords())
    ) {
      return (
        <S.PageNavListItem>
          <a onClick={e => changePage(pageNumber)}>{textLabel}</a>
        </S.PageNavListItem>
      );
    }

    return (
      <S.PageNavListItem disable>
        <a>{textLabel}</a>
      </S.PageNavListItem>
    );
  };

  const renderPagination = () => {
    const navArr: React.JSX.Element[] = [];

    if (!totalRecords || totalRecords <= 0) {
      return navArr;
    }

    const positionsAvailables = pagination(page, {
      min: 1,
      total: Math.ceil(totalRecords / getTotalRecords()),
      length: 3,
    });

    navArr.push(preparePageMovement(page - 1, 'Previous'));

    for (let i = 0; i < positionsAvailables.length; i++) {
      if (positionsAvailables[i] === page + 1) {
        navArr.push(renderActiveItem(positionsAvailables[i]));
      } else {
        navArr.push(renderInactiveItem(positionsAvailables[i]));
      }
    }

    navArr.push(preparePageMovement(page + 1, 'Next'));

    return navArr;
  };

  return (
    <nav className="--hide-from-pdf">
      <S.PageNavList>{renderPagination()}</S.PageNavList>
    </nav>
  );
};

export default Pagination;
