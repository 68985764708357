import styled from 'styled-components';

export const FiltersButton = styled.div`
  height: 4rem;
  display: flex;
  fill: #606976;
  color: #606976;
  font-weight: 400;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  position: relative;
  white-space: nowrap;
  line-height: 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #eff1f6;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    margin-right: 1rem;
    transition: transform 1s;
  }
`;

export const NotificationDot = styled.div`
  top: 1.1rem;
  left: 2.4rem;
  width: 0.9rem;
  height: 0.9rem;
  margin-left: auto;
  position: absolute;
  margin-right: auto;
  border-radius: 50%;
  background: #f5a623;
  border: solid 0.2rem #f7f8fa;
`;

export const FilterSideBarBackground = styled.div<{ active: boolean }>`
  top: 6rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  position: absolute;
  transition: all 0.08s;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const FilterSideBarContainer = styled.div`
  width: 35rem;
  height: 100%;
  padding: 2rem;
  display: flex;
  background: #fff;
  overflow-y: scroll;
  flex-direction: column;
`;

export const FilterSideBarFooterContainer = styled.div`
  right: 0;
  bottom: 0;
  gap: 1rem;
  width: 35rem;
  height: 8rem;
  padding: 2rem;
  display: flex;
  position: fixed;
  overflow-y: scroll;
  align-items: center;
  background-color: #fff;
`;

export const FilterSideBarFooterButton = styled.div<{
  secondary?: boolean;
}>`
  flex: 1;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  background-color: ${({ secondary }) => (secondary ? '#fff' : '#5870f6')};
  cursor: pointer;
`;

export const FilterSideBarFooterButtonText = styled.div<{
  secondary?: boolean;
}>`
  color: ${({ secondary }) => (secondary ? '#676767' : '#fff')};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.85;
`;
