import styled from 'styled-components';

export const ChartContentContainer = styled.div<{ customWidth?: number }>`
  flex-grow: 1;
  display: flex;
  background: #fff;
  min-height: 40rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
  flex-direction: column;
  border: solid 1px #e0e5ea;
  width: ${({ customWidth }) => (customWidth ? `${customWidth}%` : '100%')};
  justify-content: ${({ customWidth }) =>
    customWidth ? 'unset' : 'space-between'};
`;

export const ChartMetricLabel = styled.div`
  gap: 0.4rem;
  display: flex;
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
  align-items: center;
  padding: 2.4rem 3.2rem 0 3.2rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: #8492a6;
  }
`;

export const CreatorsList = styled.div`
  margin: 3.2rem;
`;

export const CreatorsListHead = styled.div`
  display: flex;
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
  align-items: center;
  justify-content: space-between;
`;

export const RankNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Rank = styled.div`
  width: 1rem;
  display: flex;
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
  margin-right: 2.2rem;
  justify-content: center;
`;

export const Name = styled.div``;

export const CreatorsMetric = styled.div``;

export const Divider = styled.div`
  height: 0.1rem;
  background-color: #e7ebf4;
`;

export const CreatorContainer = styled.div`
  display: flex;
  padding: 1.2rem 0;
  min-height: 5.6rem;
  align-items: center;
  justify-content: space-between;

  &:nth-of-type(2) {
    padding-top: 1.8rem;
  }
`;

export const CreatorAvatarContainer = styled.div`
  height: 100%;
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
`;

export const CreatorAvatar = styled.div`
  img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 9999px;
  }
`;

export const NetworkIcon = styled.div`
  padding: 0.2rem;
  background: white;
  margin-top: 2.4rem;
  align-items: center;
  margin-left: -1.6rem;
  display: inline-flex;
  border-radius: 0.6rem;
  justify-content: center;
`;

export const CreatorDetails = styled.div`
  gap: 0.2rem;
  display: flex;
  margin-left: 1.2rem;
  flex-direction: column;
`;

export const CreatorDetailMetric = styled.div`
  display: flex;
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const CreatorKpi = styled.div`
  color: #292929;
  font-weight: 600;
  font-size: 1.6rem;
`;
