import styled from 'styled-components';

export const Title = styled.div`
  font-size: 2rem;
  color: #292929;
  font-weight: 600;
  padding: 1rem 1rem 3.2rem 1rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: hidden;
  border-spacing: 0;
  background: #ffffff;
  border-radius: 1.2rem;
  border: solid 1px #e0e5ea;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media (max-width: 850px) {
    width: 100%;
    overflow-x: scroll;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    @media (max-width: 850px) {
      width: unset;
      table-layout: fixed;
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        background: #f7f8fa;
      }
    }
  }

  tr {
    height: 7.6rem;
  }

  th {
    padding: 2rem;
    color: #676767;
    font-weight: 400;
    font-size: 1.2rem;

    @media (max-width: 850px) {
      &:not(:first-child):not(:nth-child(2)) {
        min-width: 8rem;
      }
    }

    &:first-child {
      width: 8rem;
      color: #8492a6;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;

      @media (max-width: 850px) {
        left: 0;
        width: 2rem;
        position: sticky;
      }
    }

    &:nth-child(2) {
      width: 34rem;
      max-width: 20vw;
      white-space: nowrap;

      @media (max-width: 850px) {
        left: 5.6rem;
        position: sticky;
        overflow: hidden;
        min-width: 12rem;
        padding-right: 4rem;
        padding-left: 1.2rem;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }

  td {
    padding: 2rem;
    border-top: solid 1px #e0e5ea;

    @media (max-width: 850px) {
      &:not(:first-child):not(:nth-child(2)) {
        min-width: 8rem;
      }
    }

    &:first-child {
      width: 8rem;
      color: #8492a6;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;

      @media (max-width: 850px) {
        left: 0;
        width: 2rem;
        position: sticky;
      }
    }

    &:nth-child(2) {
      width: 34rem;
      max-width: 20vw;
      font-weight: 600;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 850px) {
        left: 5.6rem;
        position: sticky;
        overflow: hidden;
        min-width: 12rem;
        padding-right: 4rem;
        padding-left: 1.2rem;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  padding: 3.2rem 0;
  align-items: center;
  justify-content: center;
`;

export const LoadMoreButton = styled.div`
  display: flex;
  height: 4.4rem;
  padding: 0 2rem;
  color: #8492a6;
  font-weight: 500;
  font-size: 1.4rem;
  align-items: center;
  background: #eff1f6;
  border-radius: 2.2rem;
  cursor: pointer;
`;
