import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetLocations } from 'Services/Utils/CustomHooks';
import { LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY } from 'data/constants';
import {
  SocialNetworks,
  TCountriesAvailableValues,
} from 'Services/Utils/types';

import {
  ParamsFilters,
  SearchesLocalStorage,
  ListeningSortByField,
} from '../types';

export const useListening = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};
  const { filters }: { filters: ParamsFilters } = state || {};

  const startDate = moment().subtract(90, 'days').utc().startOf('day');
  const endDate = moment().utc().endOf('day');

  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ];
  });

  const [validFilters, setValidFilters] = useState(false);
  const [toggleSortByButton, setToggleSortByButton] = useState(false);
  const [toggleFilterButton, setToggleFilterButton] = useState(false);
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);

  const [visibleHashtagsCount, setVisibleHashtagsCount] = useState(5);
  const [previousSearchesCount, setPreviousSearchesCount] = useState(5);

  const [searchWord, setSearchWord] = useState<string | undefined>();
  const [network, setNetwork] = useState<SocialNetworks | undefined>();
  const [sortByField, setSortByField] = useState(ListeningSortByField.creators);
  const [countryCode, setCountryCode] = useState<string>(
    filters?.countryCode[0] ?? 'PT',
  );

  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);

  const storedPreviousSearches = useCallback(() => {
    const data: SearchesLocalStorage[] = JSON.parse(
      localStorage.getItem(LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY) ||
        '[]',
    );

    const key = () => {
      switch (sortByField) {
        case ListeningSortByField.creators:
          return 'numUsers';
        case ListeningSortByField.posts:
          return 'numPosts';
        case ListeningSortByField.engRate:
          return 'engRate';
        default:
          return 'numUsers';
      }
    };

    data.sort((a, b) => b[key()] - a[key()]);

    return data;
  }, [sortByField]);

  const handleGoToTermAnalytics = useCallback(
    (item?: SearchesLocalStorage) => {
      if (item && item.term.trim().length > 0) {
        navigate(`/listening/${item.term.trim()}`, {
          state: {
            filters: item.filters,
          },
        });
        return;
      }

      if (searchWord && searchWord.trim().length > 0) {
        navigate(`/listening/${searchWord.trim()}`, {
          state: {
            filters: {
              endDate: timeFrame[0].endDate.valueOf(),
              startDate: timeFrame[0].startDate.valueOf(),
              keywords: [searchWord.trim()],
              countryCode: [countryCode],
              network: network ? [network] : undefined,
            },
          },
        });
      }
    },
    [timeFrame, searchWord, countryCode, network],
  );

  const handleChangeSearchWord = useCallback((word: string) => {
    setSearchWord(word);
  }, []);

  const handleChangeSortBy = useCallback(
    (sortKey: keyof typeof ListeningSortByField) => {
      setSortByField(ListeningSortByField[sortKey]);
      setToggleSortByButton(false);
    },
    [sortByField],
  );

  const handleLoadMoreHashtags = useCallback(() => {
    setVisibleHashtagsCount(prevState => prevState + 5);
  }, []);

  const handleLoadMorePreviousSearches = useCallback(() => {
    setPreviousSearchesCount(prevState => prevState + 5);
  }, []);

  const handleToggleSortByButton = useCallback(() => {
    setTimeout(() => setToggleSortByButton(!toggleSortByButton), 0);
  }, [toggleSortByButton]);

  const handleClickOutToggleSortByButton = useCallback(() => {
    if (toggleSortByButton) {
      setToggleSortByButton(false);
    }
  }, [toggleSortByButton]);

  const handleToggleFilterButton = useCallback(() => {
    setTimeout(() => setToggleFilterButton(!toggleFilterButton), 0);
  }, [toggleFilterButton]);

  const handleClickOutToggleFilterButton = useCallback(() => {
    if (toggleFilterButton) {
      setToggleFilterButton(false);
    }
  }, [toggleFilterButton]);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const handleClearFilters = useCallback(() => {
    setSearchWord('');
    setCountryCode('PT');
    setNetwork(undefined);
    setTimeFrame([
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ]);
  }, []);

  useEffect(() => {
    if (searchWord && searchWord.trim().length > 0) {
      setValidFilters(true);
    } else {
      setValidFilters(false);
    }
  }, [searchWord]);

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setSearchWord(filters.keywords[0]);
      setNetwork(filters.network ? filters.network[0] : undefined);
      setTimeFrame([
        {
          startDate: moment(filters.startDate).toDate(),
          endDate: moment(filters.endDate).toDate(),
          key: 'selection',
        },
      ]);
    }
  }, []);

  return {
    network,
    timeFrame,
    setNetwork,
    searchWord,
    countryCode,
    sortByField,
    validFilters,
    setTimeFrame,
    setCountryCode,
    toggleFilterButton,
    toggleSortByButton,
    handleChangeSortBy,
    handleClearFilters,
    showTimeFramePicker,
    visibleHashtagsCount,
    previousSearchesCount,
    setShowTimeFramePicker,
    handleChangeSearchWord,
    handleLoadMoreHashtags,
    storedPreviousSearches,
    handleGoToTermAnalytics,
    countriesAvailableValues,
    handleToggleFilterButton,
    handleToggleSortByButton,
    handleLoadMorePreviousSearches,
    handleClickOutToggleFilterButton,
    handleClickOutToggleSortByButton,
  };
};
