import styled from 'styled-components';

export const FullWidthRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: #fff;
  min-height: 5rem;
  border-radius: 1.2rem;
  box-sizing: border-box;
  border: solid 1px #e0e5ea;
`;

export const ContentInnerContainer = styled.div`
  display: flex;
  margin: 0 3.2rem;
  flex-direction: column;
  justify-content: center;
`;

export const ContentHeadRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin-top: 1.6rem;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 800px) {
    gap: 0.4rem;
  }
`;

export const TermNameContainer = styled.div``;

export const TermName = styled.h5`
  color: #292929;
  font-weight: 600;
  font-size: 2.6rem;
  margin: 1rem 0 0.4rem 0;
`;

export const ContentMiddleRow = styled.div`
  gap: 1.6rem;
  display: flex;
  margin: 1.6rem 0;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1250px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const HashtagsRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    gap: 0.8rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  span {
    height: 3.2rem;
    color: #606976;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 0.8rem;
    display: inline-flex;
    background: #f7f8fa;
    border-radius: 1.6rem;
    padding: 0.8rem 1.4rem;
    box-sizing: border-box;
  }
`;

export const HashtagsSymbol = styled.div`
  &::after {
    content: '#';
    color: #292929;
    font-weight: 100;
    font-size: 2.4rem;
    position: relative;
    line-height: 2.6rem;
    font-family: monospace;
  }
`;

export const DateTimeframe = styled.div`
  span {
    height: 3.2rem;
    color: #606976;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 0.8rem;
    display: inline-flex;
    background: #f7f8fa;
    border-radius: 1.6rem;
    padding: 0.8rem 1.4rem;
    box-sizing: border-box;

    @media (max-width: 1250px) {
      margin-left: 0;
      color: #8492a6;
      padding: 0.8rem 0;
      background: transparent;
    }
  }
`;

export const TermKpisContainer = styled.div`
  display: flex;
  margin-top: 0.8rem;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid #e7ebf4;

  @media (max-width: 800px) {
    margin: 0;
    gap: 0 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }
`;

export const MetricContainer = styled.div`
  width: 8.8rem;
  display: grid;
  padding: 2.4rem 0;

  &:last-child {
    margin-right: 4rem;
  }

  @media (max-width: 800px) {
    margin-right: 1.6rem;
  }
`;

export const MetricValue = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
`;

export const MetricLabel = styled.span`
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
`;
