import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { SortByFields } from 'Services/SkorrApi/new/User/types';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

const DiscoverToolBarOrderByButton = () => {
  const {
    sortByField,
    toggleOrderByButton,
    handleChangeOrderBy,
    handleToggleOrderByButton,
    handleClickOutToggleOrderByButton,
  } = useDiscoverContext();

  return (
    <S.Container>
      <S.ToolBarOrderByButton
        active={toggleOrderByButton}
        onClick={handleToggleOrderByButton}
      >
        {t('discover.orderBy') + t(`discover.sortByFields.${sortByField}`)}
        <svg viewBox="0 0 17 16">
          <path
            d="M4.46858 5.03516L8.14058 8.69916L11.8126 5.03516L12.9406 6.16316L8.14058 10.9632L3.34058 6.16316L4.46858 5.03516Z"
            fill="#676767"
          />
        </svg>
      </S.ToolBarOrderByButton>

      <ClickOutHandler onClickOut={handleClickOutToggleOrderByButton}>
        <S.ToolBarOrderByDropdownContainer active={toggleOrderByButton}>
          {Object.values(SortByFields).map(item => {
            if (item === 'pulsedSkorr' || item === 'shares') return null;

            return (
              <S.ToolBarOrderByDropdownItemContainer
                key={item}
                active={sortByField === item}
                onClick={() => handleChangeOrderBy(item)}
              >
                <S.ToolBarOrderByDropdownItemText>
                  {t(`discover.sortByFields.${item}`)}
                </S.ToolBarOrderByDropdownItemText>
              </S.ToolBarOrderByDropdownItemContainer>
            );
          })}
        </S.ToolBarOrderByDropdownContainer>
      </ClickOutHandler>
    </S.Container>
  );
};

export default withTranslation()(DiscoverToolBarOrderByButton);
